import Navbar from '../../components/navbar/Navbar'
import './hotel.css'
import Card from '../../components/card/Card'
import rv from '../../img/rv.png'
import tp from '../../img/tpr.jpg'

import {california} from '../../info/california'
import {illinois} from '../../info/illinois'
import { useLocationContext } from '../../hooks/useLocationContext'

const Hotel = () => {
    const { location } = useLocationContext()

    let locationInfo;
    if (location === 'California') {
        locationInfo = california;
    } else if (location === 'Illinois') {
        locationInfo = illinois;
    } else {
        // Handle other states or invalid location
        // For example, you can display an error message or redirect to a default location.
        return (
            <div>
                Invalid location.
            </div>
        );
    }


    return (
        <div>
            <Navbar />
            <div className="hotel__container">
                <h1>Hotels</h1>
                <div className="hotel__grid">
                    {location === "California" ?
                        <div className='hotel__grid-item-special'>
                            <p className="hotel__search-cali">If you are using a hotel search website like Kayak or Travelocity, we recommend searching in <span>San Miguel</span> for proximity. This link searches hotels in the area: <a href="https://www.google.com/maps/search/Hotels/@35.7760684,-120.7057532,12z/data=!4m8!2m7!3m6!1sHotels!2s77001+Douglas+Rd,+San+Miguel,+CA+93451!3s0x80eccaefce8c5ae7:0x4b2dc87d71bd30c3!4m2!1d-120.5600642!2d35.8028643?entry=ttu ">Link</a></p>
                        </div>
                        :
                        <div className='hotel__grid-item-special'>
                            <p className="hotel__search-illinois">After a fair amount of hotel research in the area, we landed on the <span>Hampton Inn and Suites</span>, Grand Prairie as a quality yet reasonable hotel with a good location. We reserved a block of rooms here for a discount price, so we ask that you please book here under our block. To book, call <span>309-589-0001</span>, and press “0”. This will connect you to the actual front desk, rather than the corporate help line. Then just ask to reserve a room in the <span>Curiel-LaRochelle block</span>. If you have any questions about anything, please reach out to Ray!</p>
                        </div>
                    }
                    {locationInfo.hotels.map((hotel) => (
                        <div className="hotel__grid-item">
                            <Card 
                                title={hotel.title}
                                image={hotel.image}
                                paragraph={hotel.paragraph}
                                link={hotel.link} 
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Hotel