import Navbar from "../../components/navbar/Navbar"
import './registry.css'

const Registry = () => {
    return (
        <div>
            <Navbar />
            <div className="registry__container">
                <h1>Frequently Asked Questions</h1>
                <div className="registry__content">
                    <div>
                        <p className="registry__question">Question: Do you have a gift registry?</p>
                        <p>Answer: We appreciate your thoughtfulness! But no, we don’t want any gifts. We just want you to join us. However, if you have a camera, and are so inclined, we would love for you to take some nice pictures during the event. :)</p>
                        <p className="registry__question">Question: What should we wear?</p>
                        <p>Answer: The grooms will be dressed in Hawaiian shirts. Wear your favorite Hawaiian shirt, flower-y dress, or whatever casual clothing you want! We just want to eat good food, drink good drinks, and celebrate with you all.</p>
                    </div>
                    <div className="registry__design"></div>
                </div>
            </div>

        </div>
    )
}

export default Registry