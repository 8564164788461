import { LocationContext } from "../context/LocationContext"
import { useContext } from "react"

export const useLocationContext = () => {
    const context = useContext(LocationContext)

    if (!context) {
        throw Error('useLocationContext must be used inside a LocationontextProvider')
    }

    return context
}