import Navbar from '../../components/navbar/Navbar'
import './rsvp.css'
import { useState } from 'react';
import { useLocationContext } from '../../hooks/useLocationContext'
import Loading from '../../components/loading/Loading';


const Rsvp = () => {
    const [rsvp, setRSVP] = useState("");
    const [names, setNames] = useState("");
    const [notes, setNotes] = useState("");
    const [emptyFields, setEmptyFields] = useState([]);
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showLoading, setShowLoading] = useState(false); // New state variable
    const { location } = useLocationContext()


    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setShowLoading(true)
        const sendingRSVP = {location, rsvp, names, notes}

        const response = await fetch(`https://rj-wedding-api.onrender.com/api/rsvp`, {
            method: 'POST',
            body: JSON.stringify(sendingRSVP),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setError(json.error)
            setEmptyFields(json.emptyFields)
            setLoading(false)
        }
        if (response.ok) {
            setRSVP('')
            setNames('')
            setNotes('')
            setError(null)
            setEmptyFields([])
            setLoading(false)
            console.log('new rsvp added', json)
        }
    }

    const handleYes = (e) => {
        e.preventDefault();
        if (emptyFields.includes("rsvp")) {
            setEmptyFields(emptyFields.filter(field => field !== "rsvp"));
          }
        setRSVP(yes => "Yes");
    }

    const handleNo = (e) => {
        e.preventDefault();
        if (emptyFields.includes("rsvp")) {
            setEmptyFields(emptyFields.filter(field => field !== "rsvp"));
          }
        setRSVP(yes => "No");
    }

    return (
        <div>
            <Navbar />
            {showLoading && 
                <Loading 
                    loading={loading}
                    error={error}
                    showLoader={setShowLoading}
                />
            }
            <div className='rsvp__container'>
                <form>
                    <h1>RSVP</h1>
                    <label>
                        Will you be joining us?
                        {emptyFields.includes('rsvp') && <span> *</span>}
                    </label>
                    <div>
                        <button 
                            className={rsvp === "Yes" && "selected" } 
                            onClick={handleYes}
                            style={emptyFields.includes('rsvp') ? { border: "1px solid #ae2929" } : {}}
                        >
                                Yes
                        </button>
                        <button 
                            className={rsvp === "No" &&  "selected"} 
                            onClick={handleNo}
                            style={emptyFields.includes('rsvp') ? { border: "1px solid #ae2929" } : {}}
                        >
                            No
                        </button>
                    </div>
                    <label>
                        Name(s)
                        {(emptyFields.includes('names') && names === "") && <span> *</span>}
                    </label>
                    <input 
                        type="text" 
                        required="true" 
                        value={names} 
                        onChange={(e) => setNames(e.target.value)}
                        style={(emptyFields.includes('names') && names === "") ? { border: "1px solid #ae2929" } : {}}
                        >
                    </input>
                    <label>Notes</label>
                    <textarea 
                        placeholder='Comments, 
                                    questions, 
                                    concerns, 
                                    dietary restrictions'
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    >
                    </textarea>
                    <button 
                        className="rsvp__submit" 
                        onClick={submit} 
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Rsvp