import './navbar.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import caIcon from '../../img/California.png'
import ilIcon from '../../img/Illinois.png'
import { useLocationContext } from '../../hooks/useLocationContext'


const Navbar = () => {
    const [menu, setMenu] = useState(false)
    const { location } = useLocationContext()

    const handleClick = () => {
        setMenu(menu => !menu)
    }
    return (
        <header id="navbar__container">
            <div>
                <Link to="/">
                    <img 
                        src={ location === "California" ? caIcon : ilIcon} 
                        alt={`The state of ${location}`} 
                    />
                </Link>
            </div>
            <nav>
            <ul className={menu ? "active" : ""}>
          <li className={window.location.pathname === "/home" ? "selected" : ""}>
            <Link to="/home">Main</Link>
          </li>
          <li className={window.location.pathname === "/mapca" ? "selected" : ""}>
            <Link to="/mapca">Map</Link>
          </li>
          <li className={window.location.pathname === "/hotel" ? "selected" : ""}>
            <Link to="/hotel">Hotels</Link>
          </li>
          <li className={window.location.pathname === "/attraction" ? "selected" : ""}>
            <Link to="/attraction">Attractions</Link>
          </li>
          <li className={window.location.pathname === "/contact" ? "selected" : ""}>
            <Link to="/contact">Contact</Link>
          </li>
          <li className={window.location.pathname === "/registry" ? "selected" : ""}>
            <Link to="/registry">FAQ</Link>
          </li>
        </ul>
                <button onClick={handleClick} className="btn2">{menu ? "Back" : "More Information"}</button>
            </nav>
        </header>
    )
}

export default Navbar