import './home.css'
import { Link } from 'react-router-dom'
import Navbar from "../../components/navbar/Navbar"
import d3 from '../../img/d3.jpg'

import {california} from '../../info/california'
import {illinois} from '../../info/illinois'

import { useLocationContext } from '../../hooks/useLocationContext'


const Home = () => {
    const { location } = useLocationContext()

    let locationInfo;
    if (location === 'California') {
        locationInfo = california;
    } else if (location === 'Illinois') {
        locationInfo = illinois;
    } else {
        // Handle other states or invalid location
        // For example, you can display an error message or redirect to a default location.
        return (
            <div>
                Invalid location.
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <div className="home__container">
                <div className='home__details'>
                    <p id="home__date">{locationInfo.home.date}</p>
                    <img src={locationInfo.home.photo} alt={locationInfo.home.title} />
                    <h3>{locationInfo.home.title}</h3>
                    <p>{locationInfo.home.address1}</p>
                    <p>{locationInfo.home.address2}</p>
                </div>
                <div className='home__invitation'>
                    <h3>Please join us in celebration!</h3>
                    <Link to="/rsvp"  >
                        <div className="home__rsvp-button">
                            RSVP
                        </div>
                    </Link>
                    <p><strong>{locationInfo.home.time1}</strong></p>
                    <p>{locationInfo.home.time2}</p>
                </div>
            </div>
        </div>
    
    )
}

export default Home