import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Landing from './pages/landing/Landing';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Registry from './pages/registry/Registry';
import MapCA from './pages/map/MapCA';
import Hotel from './pages/hotels/Hotel';
import Attraction from './pages/attractions/Attraction';
import Rsvp from './pages/rsvp/Rsvp';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />

          <Route path="/home" element={<Home />} />
          <Route path="/rsvp" element={<Rsvp />} />
          <Route path="/mapCA" element={<MapCA />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/attraction" element={<Attraction />} />
          
          <Route path="/contact" element={<Contact />} />
          <Route path="/registry" element={<Registry />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
