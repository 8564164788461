import './loading.css'
import ScaleLoader from "react-spinners/ScaleLoader";


const Loading = ({ loading, showLoader, error }) => {

    return (
        <div className='loading__container'>
            {loading ?
                <div>
                    <p>Sending your RSVP to the server...</p>
                    <p>This can sometimes take up to 2 minutes</p>
                    <ScaleLoader className="loading__spinner" color="#36d7b7" />
                </div>
                :
                <div>
                    {error ? <p className="error">{error}</p> : <p>Your RSVP has been sent!</p>}
                    <button onClick={() => showLoader(false)}>Close</button>  
                </div>
            }
        </div>
    )
}

export default Loading