import './attraction.css'
import Navbar from '../../components/navbar/Navbar'
import Card from '../../components/card/Card'
import tp from '../../img/prc.jpg'
import w from '../../img/w.png'
import csp from '../../img/csp.png'
import ln from '../../img/ln.png'
import oa from '../../img/oa.png'

import {california} from '../../info/california'
import {illinois} from '../../info/illinois'
import { useLocationContext } from '../../hooks/useLocationContext'

const Attraction = () => {
    const { location } = useLocationContext()

    let locationInfo;
    if (location === 'California') {
        locationInfo = california;
    } else if (location === 'Illinois') {
        locationInfo = illinois;
    } else {
        // Handle other states or invalid location
        // For example, you can display an error message or redirect to a default location.
        return (
            <div>
                Invalid location.
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <div className='attraction__container'>
                <h1>Attractions</h1>
                <div className="attraction__grid">
                    {locationInfo.attractions.map((attraction) => (
                        <div className='attraction__grid-item'>
                            <Card 
                                title={attraction.title}
                                image={attraction.image}
                                paragraph={attraction.paragraph}
                                link={attraction.link} 
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Attraction