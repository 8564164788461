import d3 from '../img/d3.jpg'
import tp from '../img/prc.jpg'
import w from '../img/w.png'
import csp from '../img/csp.png'
import ln from '../img/ln.png'
import oa from '../img/oa.png'
import rv from '../img/rv.png'
import tph from '../img/tpr.jpg'

export const california = {
    home: {
        date: "Saturday, May 4th, 2024",
        photo: d3,
        title: "LaRochelle Residence",
        address1: "77001 Douglas Road",
        address2: "San Miguel CA 93451",
        time1: "Ceremony at 2:30pm",
        time2: "Appetizers and drinks will be available around 1:30pm and an early dinner will be provided after the ceremony, around 3:15pm"
    },
    attractions: [
        {
            title: "Travel Paso",
            image: tp,
            paragraph: "General info on things to do in Paso Robles.",
            link: "https://www.travelpaso.com/things-to-do/"
        },
        {
            title: "Wineries",
            image: w,
            paragraph: "Get information on local wineries.",
            link: "https://pasowine.com/visit/wineries/" 
        },
        {
            title: "Cayucos State Park",
            image: csp,
            paragraph: "Cayucos is a charming little beach town rich in history and popular for its great beaches.",
            link: "https://www.parks.ca.gov/?page_id=596"
        },
        {
            title: "Lake Nacimiento",
            image: ln,
            paragraph: "Just a few minutes from downtown is Lake Nacimiento, known as “The Dragon” for its unique shape of waterways, coves and winding tributaries.",
            link: "https://www.travelpaso.com/things-to-do/outdoors/lake-nacimiento/"
        },
        {
            title: "Hiking and Outdoor Adventures",
            image: oa,
            paragraph: "Information on hiking, biking, camping, great views, and more!",
            link: "https://www.theoutbound.com/united-states/california/paso-robles/hiking"
        }
    ],
    hotels: [
        {
            title: "More Options - Travel Paso",
            image: tph,
            paragraph: "General info on things to do in Paso Robles.",
            link: "https://www.travelpaso.com/places-to-stay/" 
        },
        {
            title: "More Options - RV Rentals",
            image: rv,
            paragraph: "Rent an RV.",
            link: "https://rvshare.com/rv-rental/paso-robles/ca"
        }
    ]
}
