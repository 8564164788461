import './card.css'

const Card = (props) => {
    return (
        <div className="card__container">
            <h3>{props.title}</h3>
            <img src={props.image}></img>
            {/* <p>{props.paragraph}</p> */}
            <a href={props.link} target="_blank">Link</a>
        </div>
    )
}

export default Card