import toth1 from '../img/toth1.jpg'
import hampton from '../img/hampton_il.avif'
import il1 from '../img/il1.jpg'
import il2 from '../img/il2.png'
import il3 from '../img/il3.jpg'
import il4 from '../img/il4.jpg'
import il5 from '../img/il5.jpg'
import deer from '../img/deer.jpg'
import il7 from '../img/il7.png'
import il8 from '../img/downtown_logo.png'

export const illinois = {
    home: {
        date: "Saturday, July 13, 2024",
        photo: toth1,
        title: "Top of the Hill Farm",
        address1: "21427 N Benedict St",
        address2: "Chillicothe, IL 61523",
        time1: "Ceremony at 4:00pm",
        time2: "Appetizers and drinks will be available starting 3:15pm and dinner will be provided after the ceremony, around 5:30pm"
    },
    attractions: [
        {
            title: "Events at the Riverfront",
            image: il7,
            paragraph: "Sometimes there are events on The Riverfront.",
            link: "https://www.visitdowntownpeoria.com/riverfront-district"
        },
        {
            title: "Events in Downtown Peoria",
            image: il8,
            paragraph: "There may be other events in one of the four popular districts in Downtown Peoria.",
            link: "https://www.visitdowntownpeoria.com/event/showall/"
        },
        {
            title: "The Shoppes at Grand Prairie",
            image: il5,
            paragraph: "A fun outdoor shopping mall.",
            link: "https://www.tripadvisor.com/Attraction_Review-g36538-d12910565-Reviews-The_Shopps_at_Grand_Prairie-Peoria_Illinois.html"
        },
        {
            title: "Peoria Zoo",
            image: il4,
            paragraph: "The Peoria Zoo is home to over 100 species of animals from around the world.",
            link: "https://www.tripadvisor.com/Attraction_Review-g36538-d631394-Reviews-Peoria_Zoo-Peoria_Illinois.html"
        },
        {
            title: "Drive through the Grandview Drive",
            image: il2,
            paragraph: "A 2 mile drive through a beautiful residential area as well as some fantastic panoramic views of the Illinois River to the east.",
            link: "https://www.tripadvisor.com/Attraction_Review-g36538-d8325479-Reviews-Grand_View_Drive-Peoria_Illinois.html" 
        },
        {
            title: "Forest Park Nature Center",
            image: il3,
            paragraph: "A Peoria Park District facility, Forest Park serves as the doorway to more than 500 acres designated as part of the Illinois State Nature Preserve.",
            link: "https://www.tripadvisor.com/Attraction_Review-g36538-d4375635-Reviews-Forest_Park_Nature_Center-Peoria_Illinois.html"
        },
        {
            title: "Prairie Park",
            image: deer,
            paragraph: "Wildlife, lodging, hiking, kayaking, and many other outdoor activities.",
            link: "https://wildlifeprairiepark.org/"
        },
        {
            title: "Other Events: Trip Advisor",
            image: il1,
            paragraph: "Top things to do in Peoria.",
            link: "https://www.tripadvisor.com/Attractions-g36538-Activities-Peoria_Illinois.html"
        },
    ],
    hotels: [
        {
            title: "Hampton Inn & Suites",
            image: hampton,
            paragraph: "After a fair amount of hotel research in the area, we landed on the Hampton Inn and Suites, Grand Prairie as a quality yet reasonable hotel with a good location. We reserved a block of rooms here for a discount price, so we ask that you please book here under our block. To book, call 309-589-0001, and press “0”. This will connect you to the actual front desk, rather than the corporate help line. Then just ask to reserve a room in the Curiel-LaRochelle block. If you have any questions about anything, please reach out to Ray!",
            link: "https://www.hilton.com/en/hotels/piewehx-hampton-suites-peoria-at-grand-prairie-il/?SEO_id=GMB-AMER-HX-PIEWEHX&y_source=1_MjA4NTAwNy03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D" 
        }
    ]
}
