import './landing.css'
import rj1 from '../../img/rj1.png'
import rj2 from '../../img/rj2.png'
import rj3 from '../../img/rj3.png'
import rj4 from '../../img/rj4.png'
import rj5 from '../../img/rj5.png'
import rj6 from '../../img/rj6.png'
import rj7 from '../../img/rj7.png'
import rj8 from '../../img/rj8.png'
import rj9 from '../../img/rj9.png'

// import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useLocationContext } from '../../hooks/useLocationContext'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';



const Landing = () => {
    const { dispatch } = useLocationContext()

    const handleCA = () => {
        dispatch({ type: 'SET_LOCATION', payload: 'California' })
        localStorage.setItem('urlLocation', 'California')
    }
    const handleIL = () => {
        dispatch({ type: 'SET_LOCATION', payload: 'Illinois' })
        localStorage.setItem('urlLocation', 'Illinois')
    }
    
    return (
        <div className='special-background'>
            <div className="landing__container">
                <h1>Raymond and Javier</h1>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide className="swiper__card"><img src={rj1} alt="Ray and Javi" /></SwiperSlide>
                    <SwiperSlide><img src={rj7} alt="Ray and Javi" /></SwiperSlide>
                    <SwiperSlide><img src={rj3} alt="Ray and Javi" /></SwiperSlide>
                    <SwiperSlide><img src={rj4} alt="Ray and Javi" /></SwiperSlide>
                    <SwiperSlide><img src={rj5} alt="Ray and Javi" /></SwiperSlide>
                    <SwiperSlide><img src={rj8} alt="Ray and Javi" /></SwiperSlide>
                    {/* <SwiperSlide><img src={rj2} alt="Ray and Javi" /></SwiperSlide> */}
                    {/* <SwiperSlide><img src={rj6} alt="Ray and Javi" /></SwiperSlide> */}
                </Swiper>
                <p>Which event are you invited to?</p>
                <div className="landing__buttons">
                    <Link to="/home" onClick={handleCA}>
                        California
                    </Link>
                    <Link to="/home" onClick={handleIL}>
                        Illinois
                    </Link>
                </div>
                {/* <a href="https://www.freepik.com/free-photo/wooden-boards-with-flashes-light_983524.htm#query=rustic%20wood%20background%20with%20lights&position=0&from_view=keyword&track=ais">Image by mrsiraphol</a> on Freepik */}
            </div>
        </div>
    )
}

export default Landing;