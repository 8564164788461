import Navbar from '../../components/navbar/Navbar';
import './map.css'
import { useLocationContext } from '../../hooks/useLocationContext'

const MapCA = () => {
    const { location } = useLocationContext()

    return (
        <div>
            <Navbar />
            {location === "California" ?
            <div className="map__container">
                <h1>Map</h1>
                <div>
                    <h4>WARNING!</h4> 
                    <p>When driving from 101 into the country, GPS systems often do not work. We recommend printing directions out before you leave.</p>
                </div>
                
                <h3>LaRochelle Residence</h3>
                <iframe src="https://storage.googleapis.com/maps-solutions-mcayez83wv/locator-plus/c4tc/locator-plus.html"
                style={{border:0}}
                >
                </iframe>

            </div>
            :
            <div className="map__container">
                <h1>Map</h1>
                <h3>Top of the Hill Farm</h3>
                <iframe src="https://storage.googleapis.com/maps-solutions-mcayez83wv/locator-plus/aqw2/locator-plus.html"
                    style={{border:0}}
                >
                </iframe>
            </div>
            }
        </div>
    )
}

export default MapCA