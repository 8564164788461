import { createContext, useReducer, useEffect } from "react";

export const LocationContext = createContext()

export const locationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOCATION':
            return {
                location: action.payload
            }
        default:
            return state
    }
}

export const LocationContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(locationReducer, {
      location: null
    });
  
    // Retrieve data from localStorage when the provider is initialized
    useEffect(() => {
        const storedLocation = localStorage.getItem('urlLocation');
        console.log(storedLocation)
        if (storedLocation) {
        //   const parsedLocation = JSON.parse(storedLocation);
          dispatch({ type: 'SET_LOCATION', payload: storedLocation });
        }
      }, []);
  
    // Store data in localStorage when it changes
    // useEffect(() => {
    //   localStorage.setItem('urlLocation', state.location);
    // }, [state.location]);

    return (
        <LocationContext.Provider value={{...state, dispatch}}>
            { children }
        </LocationContext.Provider>
    )
}