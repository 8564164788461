import Navbar from '../../components/navbar/Navbar'
import './contact.css'

const Contact = () => {
    return (
        <div>
            <Navbar />
            <div className='contact__container'>
                <h1>Contact Info</h1>
                <div className='contact__content'>
                    <h3>Raymond</h3>
                    <p>408 528 5883</p>
                    <h3>Javier</h3>
                    <p>309 369 3084</p>
                    <h3>Email</h3>
                    <p>rlarochelle89@gmail.com</p>
                    <h3>Home</h3>
                    <p>4335 N Sacramento Avenue</p> 
                    <p>Apt 2</p>
                    <p>Chicago IL 60618</p>
                </div>
            </div>
        </div>
    )
}

export default Contact